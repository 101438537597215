/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { RiArrowRightSLine, RiArrowLeftSLine, RiFileDownloadLine, RiPrinterLine, RiMailLine } from "react-icons/ri"

const EmployeeHandbookPage = () => {
  return (
    <Layout>
      <SEO 
        title="Employee Handbook Template | Customizable HR Policy Document"
        description="Download our comprehensive employee handbook template with customizable sections covering workplace policies, benefits, code of conduct, and legal compliance."
        keywords={[
          "employee handbook template",
          "company policy document",
          "workplace guidelines",
          "HR policy template",
          "staff handbook example",
          "printable employee manual"
        ]}
      />
      
      {/* Hero Section */}
      <section sx={{
        bg: 'primaryMuted',
        pt: [4, 5],
        pb: [4, 5]
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
          }}>
            <h1 sx={{
              fontSize: ['2rem', '2.5rem'],
              fontWeight: 800,
              mb: 3,
              color: 'primary'
            }}>
              Employee Handbook Template
            </h1>
            
            <p sx={{
              fontSize: ['1.1rem', '1.25rem'],
              maxWidth: '800px',
              mb: 4,
              lineHeight: 1.5,
              color: 'text'
            }}>
              A comprehensive, customizable template to establish clear workplace policies,
              procedures, and expectations for your organization.
            </p>
            
            <div sx={{
              display: 'flex',
              gap: 3,
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}>
              <Link to="#download" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'primary',
                color: 'white',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '1rem',
                fontWeight: 600,
                textDecoration: 'none',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  color: 'white'
                }
              }}>
                Download Full Template <RiFileDownloadLine />
              </Link>
              
              <button onClick={() => window.print()} sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '1rem',
                fontWeight: 600,
                border: 'none',
                cursor: 'pointer',
                boxShadow: '0 2px 6px rgba(0,0,0,0.05)',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                }
              }}>
                Print Preview <RiPrinterLine />
              </button>
            </div>
          </div>
        </div>
      </section>
      
      {/* Main Content */}
      <section sx={{
        py: 5,
        bg: 'white'
      }}>
        <div sx={{
          maxWidth: '1080px',
          mx: 'auto',
          px: 3,
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '280px 1fr'],
          gap: 4
        }}>
          {/* Table of Contents Sidebar */}
          <div sx={{
            bg: 'white',
            p: 3,
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            alignSelf: 'start',
            position: ['static', 'static', 'sticky'],
            top: '20px',
            display: ['none', 'none', 'block']
          }}>
            <h3 sx={{
              fontSize: '1.25rem',
              fontWeight: 600,
              mb: 3,
              color: 'primary'
            }}>
              Table of Contents
            </h3>
            
            <nav>
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0
              }}>
                <li sx={{ mb: 2 }}>
                  <Link to="#introduction" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Introduction
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#company-overview" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Company Overview
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#employment-policies" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Employment Policies
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#workplace-conduct" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Workplace Conduct
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#compensation-benefits" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Compensation & Benefits
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#time-off" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Time Off & Leave
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#health-safety" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Health & Safety
                  </Link>
                </li>
                <li sx={{ mb: 0 }}>
                  <Link to="#acknowledgment" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Acknowledgment Form
                  </Link>
                </li>
              </ul>
            </nav>
            
            <div sx={{
              mt: 4,
              pt: 4,
              borderTop: '1px solid',
              borderColor: 'rgba(0,0,0,0.1)'
            }}>
              <Link to="#download" sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                color: 'primary',
                textDecoration: 'none',
                fontSize: '0.95rem',
                fontWeight: 600
              }}>
                <RiFileDownloadLine /> Download Template
              </Link>
            </div>
          </div>
          
          {/* Main Content Area */}
          <div>
            {/* Introduction Section */}
            <div id="introduction" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                Introduction
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  Welcome to the [Company Name] Employee Handbook. This document is designed to provide you with important 
                  information about our company's policies, procedures, and expectations. It outlines your rights and 
                  responsibilities as an employee and serves as a reference guide throughout your employment.
                </p>
                
                <p>
                  This handbook is not a contract of employment, nor does it guarantee employment for any specific 
                  duration. Employment with [Company Name] is "at-will," meaning either you or the company may 
                  terminate the employment relationship at any time, with or without cause or notice.
                </p>
                
                <p>
                  The policies described in this handbook replace all previous policies, procedures, and practices, 
                  whether written or oral. [Company Name] reserves the right to revise, supplement, or rescind any 
                  policies or portion of this handbook as it deems appropriate, at its sole and absolute discretion.
                </p>
                
                <p>
                  <strong>Purpose of This Handbook:</strong>
                </p>
                
                <ul sx={{
                  pl: 4,
                  '& li': {
                    mb: 2
                  }
                }}>
                  <li>To communicate [Company Name]'s policies, procedures, and standards</li>
                  <li>To outline employee benefits and entitlements</li>
                  <li>To establish clear expectations for workplace conduct</li>
                  <li>To provide information on compliance with laws and regulations</li>
                  <li>To familiarize employees with company culture and values</li>
                </ul>
              </div>
            </div>
            
            {/* Company Overview Section */}
            <div id="company-overview" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                Company Overview
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  color: 'text'
                }}>
                  Mission Statement
                </h3>
                
                <p>
                  [Insert your company's mission statement here. Describe your company's purpose, 
                  what it aims to achieve, and the principles that guide its actions.]
                </p>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Core Values
                </h3>
                
                <p>
                  Our core values define who we are as an organization and guide our decisions and actions:
                </p>
                
                <ul sx={{
                  pl: 4,
                  '& li': {
                    mb: 2
                  }
                }}>
                  <li><strong>Integrity:</strong> We act with honesty and adhere to the highest ethical standards.</li>
                  <li><strong>Innovation:</strong> We embrace change and constantly seek new and better ways to serve our customers.</li>
                  <li><strong>Collaboration:</strong> We work together across boundaries to meet the needs of our customers and help the company win.</li>
                  <li><strong>Excellence:</strong> We are committed to delivering high-quality products and services.</li>
                  <li><strong>Respect:</strong> We value differences, listen to understand, and treat others with dignity.</li>
                </ul>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Company History
                </h3>
                
                <p>
                  [Provide a brief history of your company, including when it was founded, major milestones, 
                  and how it has evolved over time. This helps employees understand the context and heritage 
                  of the organization they're joining.]
                </p>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Organizational Structure
                </h3>
                
                <p>
                  [Describe your company's organizational structure, including departments, reporting 
                  relationships, and leadership hierarchy. You may want to include an organizational 
                  chart as an appendix.]
                </p>
              </div>
            </div>
            
            {/* Employment Policies Section */}
            <div id="employment-policies" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                Employment Policies
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  color: 'text'
                }}>
                  Equal Employment Opportunity
                </h3>
                
                <p>
                  [Company Name] is committed to providing equal employment opportunities to all employees and 
                  applicants without regard to race, color, religion, sex, national origin, age, disability, 
                  genetic information, veteran status, or any other protected characteristic as outlined by 
                  federal, state, or local laws.
                </p>
                
                <p>
                  This policy applies to all terms and conditions of employment, including but not limited to 
                  hiring, placement, promotion, termination, layoff, recall, transfer, leaves of absence, 
                  compensation, and training.
                </p>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Employment Classifications
                </h3>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3,
                  mb: 3
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'text'
                  }}>
                    Employment Status
                  </h4>
                  
                  <ul sx={{
                    pl: 0,
                    mb: 0,
                    listStyle: 'none'
                  }}>
                    <li sx={{ mb: 2 }}>
                      <strong>Full-time:</strong> Employees who are regularly scheduled to work [Company Name]'s full-time 
                      schedule of [X] hours per week.
                    </li>
                    <li sx={{ mb: 2 }}>
                      <strong>Part-time:</strong> Employees who are regularly scheduled to work less than [X] hours per week.
                    </li>
                    <li sx={{ mb: 2 }}>
                      <strong>Temporary:</strong> Employees hired for a specific project or time period, typically less than 
                      [X] months in duration.
                    </li>
                    <li>
                      <strong>Seasonal:</strong> Employees hired for a specific season or period of the year, typically 
                      during peak business periods.
                    </li>
                  </ul>
                </div>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'text'
                  }}>
                    FLSA Classifications
                  </h4>
                  
                  <ul sx={{
                    pl: 0,
                    mb: 0,
                    listStyle: 'none'
                  }}>
                    <li sx={{ mb: 2 }}>
                      <strong>Exempt:</strong> Employees who are exempt from the overtime provisions of the Fair Labor 
                      Standards Act (FLSA) and do not receive overtime pay.
                    </li>
                    <li>
                      <strong>Non-exempt:</strong> Employees who are covered by the overtime provisions of the FLSA and 
                      are entitled to overtime pay for hours worked beyond 40 in a workweek.
                    </li>
                  </ul>
                </div>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Probationary Period
                </h3>
                
                <p>
                  All new employees undergo a [X]-day probationary period to determine whether they are suited 
                  for their position. During this period, employees are evaluated on their performance, attendance, 
                  attitude, and ability to meet job requirements.
                </p>
                
                <p>
                  Completion of the probationary period does not alter the at-will employment relationship, nor 
                  does it guarantee employment for any specific duration.
                </p>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Personnel Files & Data Changes
                </h3>
                
                <p>
                  [Company Name] maintains a personnel file for each employee. Personnel files are the property 
                  of the company, and access to the information is restricted according to applicable laws.
                </p>
                
                <p>
                  Employees are responsible for promptly notifying their supervisor or HR of any changes in 
                  personal data, including:
                </p>
                
                <ul sx={{
                  pl: 4,
                  mb: 0,
                  '& li': {
                    mb: 2
                  },
                  '& li:last-child': {
                    mb: 0
                  }
                }}>
                  <li>Name, address, and telephone number</li>
                  <li>Marital status and dependents</li>
                  <li>Emergency contact information</li>
                  <li>Educational accomplishments</li>
                  <li>Banking information (for direct deposit)</li>
                  <li>Tax withholding information</li>
                </ul>
              </div>
            </div>
            
            {/* Workplace Conduct Section */}
            <div id="workplace-conduct" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                Workplace Conduct
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  color: 'text'
                }}>
                  Code of Ethics
                </h3>
                
                <p>
                  [Company Name] is committed to maintaining the highest standards of ethical conduct. All 
                  employees are expected to:
                </p>
                
                <ul sx={{
                  pl: 4,
                  '& li': {
                    mb: 2
                  }
                }}>
                  <li>Act with honesty and integrity in all business dealings</li>
                  <li>Comply with all applicable laws, regulations, and company policies</li>
                  <li>Avoid conflicts of interest and disclose potential conflicts promptly</li>
                  <li>Protect confidential and proprietary information</li>
                  <li>Treat colleagues, customers, vendors, and other stakeholders with respect</li>
                  <li>Report suspected violations of the code of ethics without fear of retaliation</li>
                </ul>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Anti-Harassment & Non-Discrimination
                </h3>
                
                <p>
                  [Company Name] is committed to providing a work environment free from harassment and 
                  discrimination. We prohibit harassment or discrimination based on race, color, religion, 
                  sex, national origin, age, disability, genetic information, veteran status, or any other 
                  protected characteristic.
                </p>
                
                <div sx={{
                  bg: 'primaryMuted',
                  p: 3,
                  borderRadius: '6px',
                  my: 3
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'primary'
                  }}>
                    Examples of Prohibited Conduct:
                  </h4>
                  
                  <ul sx={{
                    pl: 4,
                    mb: 0,
                    '& li': {
                      mb: 2
                    },
                    '& li:last-child': {
                      mb: 0
                    }
                  }}>
                    <li>Offensive remarks, jokes, slurs, or epithets</li>
                    <li>Unwelcome sexual advances or requests for sexual favors</li>
                    <li>Display of offensive images, posters, or materials</li>
                    <li>Physical harassment, including touching or assault</li>
                    <li>Threats or intimidation based on protected characteristics</li>
                    <li>Retaliation for reporting harassment or discrimination</li>
                  </ul>
                </div>
                
                <p>
                  Any employee who experiences or witnesses harassment or discrimination should report 
                  it immediately to their supervisor, HR, or any member of management. All reports will 
                  be investigated promptly and confidentially to the extent possible.
                </p>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Workplace Violence Prevention
                </h3>
                
                <p>
                  [Company Name] is committed to preventing workplace violence. We have zero tolerance for 
                  violent acts or threats directed toward employees, customers, vendors, or company property.
                </p>
                
                <p>
                  All employees should report any potentially dangerous situations or concerns immediately 
                  to their supervisor or HR. Reports can be made without fear of retaliation.
                </p>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Attendance & Punctuality
                </h3>
                
                <p>
                  Regular attendance and punctuality are essential job functions. Employees are expected to:
                </p>
                
                <ul sx={{
                  pl: 4,
                  mb: 0,
                  '& li': {
                    mb: 2
                  },
                  '& li:last-child': {
                    mb: 0
                  }
                }}>
                  <li>Report to work on time and as scheduled</li>
                  <li>Notify their supervisor as early as possible if they will be late or absent</li>
                  <li>Schedule time off in advance whenever possible</li>
                  <li>Provide appropriate documentation for absences when required</li>
                </ul>
                
                <p sx={{ mt: 3 }}>
                  Excessive absenteeism or tardiness may result in disciplinary action, up to and including 
                  termination of employment.
                </p>
              </div>
            </div>
            
            {/* Compensation & Benefits Section */}
            <div id="compensation-benefits" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                Compensation & Benefits
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  color: 'text'
                }}>
                  Pay Practices
                </h3>
                
                <p>
                  Employees are paid [weekly/bi-weekly/monthly] on [day of week/date]. When a payday falls on a 
                  holiday, employees will be paid on the last working day before the holiday.
                </p>
                
                <p>
                  [Company Name] offers direct deposit of paychecks as a convenience for employees. All employees 
                  are strongly encouraged to use direct deposit.
                </p>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Work Hours & Overtime
                </h3>
                
                <p>
                  Standard work hours are [define hours, e.g., Monday through Friday, 9:00 am to 5:00 pm]. Department 
                  managers may establish alternative work schedules to accommodate business needs.
                </p>
                
                <p>
                  Non-exempt employees will be paid overtime at the rate of 1.5 times their regular hourly rate for 
                  hours worked in excess of 40 in a workweek. All overtime must be approved in advance by the 
                  employee's supervisor.
                </p>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Employee Benefits
                </h3>
                
                <p>
                  [Company Name] offers a comprehensive benefits package for eligible employees. Details of these 
                  benefits are provided separately in the benefits enrollment materials. Available benefits may include:
                </p>
                
                <div sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 3,
                  mt: 3
                }}>
                  <div sx={{
                    bg: '#f8f9fa',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'text'
                    }}>
                      Medical & Wellness
                    </h4>
                    
                    <ul sx={{
                      pl: 4,
                      mb: 0,
                      '& li': {
                        mb: 2
                      },
                      '& li:last-child': {
                        mb: 0
                      }
                    }}>
                      <li>Medical insurance</li>
                      <li>Dental insurance</li>
                      <li>Vision insurance</li>
                      <li>Flexible spending accounts</li>
                      <li>Employee assistance program</li>
                      <li>Wellness initiatives</li>
                    </ul>
                  </div>
                  
                  <div sx={{
                    bg: '#f8f9fa',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'text'
                    }}>
                      Financial Security
                    </h4>
                    
                    <ul sx={{
                      pl: 4,
                      mb: 0,
                      '& li': {
                        mb: 2
                      },
                      '& li:last-child': {
                        mb: 0
                      }
                    }}>
                      <li>401(k) retirement plan</li>
                      <li>Life insurance</li>
                      <li>Disability insurance</li>
                      <li>Workers' compensation</li>
                      <li>Stock options/purchase plan</li>
                      <li>Profit-sharing</li>
                    </ul>
                  </div>
                </div>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Professional Development
                </h3>
                
                <p>
                  [Company Name] supports the professional growth and development of its employees. We may provide:
                </p>
                
                <ul sx={{
                  pl: 4,
                  mb: 0,
                  '& li': {
                    mb: 2
                  },
                  '& li:last-child': {
                    mb: 0
                  }
                }}>
                  <li>On-the-job training</li>
                  <li>Tuition reimbursement for approved courses</li>
                  <li>Professional certification support</li>
                  <li>Internal career advancement opportunities</li>
                  <li>Workshops and seminars</li>
                  <li>Professional association memberships</li>
                </ul>
              </div>
            </div>
            
            {/* Time Off & Leave Section */}
            <div id="time-off" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                Time Off & Leave
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  color: 'text'
                }}>
                  Holidays
                </h3>
                
                <p>
                  [Company Name] observes the following paid holidays each year:
                </p>
                
                <ul sx={{
                  pl: 4,
                  mb: 3,
                  '& li': {
                    mb: 1
                  }
                }}>
                  <li>New Year's Day</li>
                  <li>Martin Luther King Jr. Day</li>
                  <li>Memorial Day</li>
                  <li>Independence Day</li>
                  <li>Labor Day</li>
                  <li>Thanksgiving Day</li>
                  <li>Day after Thanksgiving</li>
                  <li>Christmas Eve</li>
                  <li>Christmas Day</li>
                </ul>
                
                <p>
                  When a holiday falls on a weekend, it will typically be observed on the preceding Friday or 
                  following Monday. The company reserves the right to modify this holiday schedule at its discretion.
                </p>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Paid Time Off (PTO)
                </h3>
                
                <p>
                  [Company Name] provides Paid Time Off (PTO) to eligible employees for vacation, personal days, 
                  and short-term illnesses. PTO accrual is based on length of service and employment status as follows:
                </p>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3,
                  my: 3,
                  overflowX: 'auto'
                }}>
                  <table sx={{
                    width: '100%',
                    borderCollapse: 'collapse',
                    fontSize: '0.9rem'
                  }}>
                    <thead>
                      <tr>
                        <th sx={{ 
                          textAlign: 'left', 
                          p: 2, 
                          borderBottom: '1px solid #e9ecef'
                        }}>
                          Years of Service
                        </th>
                        <th sx={{ 
                          textAlign: 'center', 
                          p: 2, 
                          borderBottom: '1px solid #e9ecef'
                        }}>
                          Annual PTO Days (Full-Time)
                        </th>
                        <th sx={{ 
                          textAlign: 'center', 
                          p: 2, 
                          borderBottom: '1px solid #e9ecef'
                        }}>
                          Accrual Rate (Per Pay Period)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td sx={{ 
                          p: 2, 
                          borderBottom: '1px solid #e9ecef'
                        }}>
                          0-2 years
                        </td>
                        <td sx={{ 
                          textAlign: 'center', 
                          p: 2, 
                          borderBottom: '1px solid #e9ecef'
                        }}>
                          10 days
                        </td>
                        <td sx={{ 
                          textAlign: 'center', 
                          p: 2, 
                          borderBottom: '1px solid #e9ecef'
                        }}>
                          3.08 hours
                        </td>
                      </tr>
                      <tr>
                        <td sx={{ 
                          p: 2, 
                          borderBottom: '1px solid #e9ecef'
                        }}>
                          3-5 years
                        </td>
                        <td sx={{ 
                          textAlign: 'center', 
                          p: 2, 
                          borderBottom: '1px solid #e9ecef'
                        }}>
                          15 days
                        </td>
                        <td sx={{ 
                          textAlign: 'center', 
                          p: 2, 
                          borderBottom: '1px solid #e9ecef'
                        }}>
                          4.62 hours
                        </td>
                      </tr>
                      <tr>
                        <td sx={{ p: 2 }}>
                          6+ years
                        </td>
                        <td sx={{ 
                          textAlign: 'center', 
                          p: 2
                        }}>
                          20 days
                        </td>
                        <td sx={{ 
                          textAlign: 'center', 
                          p: 2
                        }}>
                          6.15 hours
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
                <p>
                  PTO requests should be submitted to supervisors as far in advance as possible. Approval is subject 
                  to business needs and departmental staffing requirements.
                </p>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Leave of Absence
                </h3>
                
                <p>
                  [Company Name] provides various types of leaves of absence in accordance with federal and state laws, 
                  including:
                </p>
                
                <ul sx={{
                  pl: 4,
                  mb: 0,
                  '& li': {
                    mb: 2
                  },
                  '& li:last-child': {
                    mb: 0
                  }
                }}>
                  <li><strong>Family and Medical Leave (FMLA):</strong> Eligible employees may take up to 12 weeks of unpaid leave for qualifying family or medical reasons.</li>
                  <li><strong>Military Leave:</strong> Leave for military service in accordance with the Uniformed Services Employment and Reemployment Rights Act (USERRA).</li>
                  <li><strong>Bereavement Leave:</strong> Typically [X] days for immediate family members and [Y] days for extended family.</li>
                  <li><strong>Jury Duty:</strong> Paid leave for the duration of jury service as required by law.</li>
                  <li><strong>Voting Time:</strong> Reasonable time off to vote in federal, state, or local elections.</li>
                </ul>
              </div>
            </div>
            
            {/* Health & Safety Section */}
            <div id="health-safety" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                Health & Safety
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  color: 'text'
                }}>
                  Workplace Safety
                </h3>
                
                <p>
                  [Company Name] is committed to providing a safe and healthy workplace for all employees. 
                  We comply with all applicable safety laws and regulations and maintain safety policies and 
                  procedures designed to protect our employees.
                </p>
                
                <p>
                  All employees are expected to:
                </p>
                
                <ul sx={{
                  pl: 4,
                  mb: 3,
                  '& li': {
                    mb: 2
                  }
                }}>
                  <li>Follow all safety rules and procedures</li>
                  <li>Report unsafe conditions or practices immediately</li>
                  <li>Report all accidents, injuries, and illnesses promptly</li>
                  <li>Participate in safety training</li>
                  <li>Use required personal protective equipment</li>
                </ul>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Emergency Procedures
                </h3>
                
                <p>
                  The company maintains emergency procedures for various situations, including:
                </p>
                
                <div sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 3,
                  mt: 3
                }}>
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Fire Safety
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      In case of fire, activate the nearest fire alarm, evacuate the building using designated 
                      exits, and assemble at the designated meeting point. Do not use elevators during a fire emergency.
                    </p>
                  </div>
                  
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Medical Emergencies
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      For medical emergencies, call 911 immediately and then notify a supervisor. First aid kits 
                      are located [describe locations]. Only trained personnel should administer first aid.
                    </p>
                  </div>
                </div>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Drug & Alcohol Policy
                </h3>
                
                <p>
                  [Company Name] is committed to maintaining a workplace free from the effects of drugs and alcohol. 
                  The use, possession, distribution, or sale of illegal drugs on company premises or while conducting 
                  company business is strictly prohibited.
                </p>
                
                <p>
                  Employees are prohibited from working while under the influence of alcohol, illegal drugs, or 
                  controlled substances that impair their ability to perform their job safely and effectively.
                </p>
                
                <p>
                  The company reserves the right to conduct drug testing in accordance with applicable laws and 
                  regulations.
                </p>
              </div>
            </div>
            
            {/* Acknowledgment Form Section */}
            <div id="acknowledgment" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                Acknowledgment Form
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  This Employee Handbook contains important information about [Company Name]'s policies, procedures, 
                  and benefits. I understand that I should consult my supervisor or Human Resources regarding any 
                  questions not answered in the handbook.
                </p>
                
                <p>
                  I understand that this handbook is not an employment contract or a legal document. I have received 
                  the handbook, and I understand that it is my responsibility to read and comply with the policies 
                  contained in this handbook and any revisions made to it.
                </p>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 4,
                  mt: 4
                }}>
                  <div sx={{
                    display: 'grid',
                    gridTemplateColumns: ['1fr', '1fr 1fr'],
                    gap: 4,
                    mb: 4
                  }}>
                    <div>
                      <p sx={{ mb: 1, fontWeight: 600 }}>Employee Name (Print):</p>
                      <div sx={{ 
                        borderBottom: '1px solid #ced4da',
                        height: '1.5rem'
                      }}></div>
                    </div>
                    <div>
                      <p sx={{ mb: 1, fontWeight: 600 }}>Employee ID:</p>
                      <div sx={{ 
                        borderBottom: '1px solid #ced4da',
                        height: '1.5rem'
                      }}></div>
                    </div>
                  </div>
                  
                  <div sx={{
                    display: 'grid',
                    gridTemplateColumns: ['1fr', '1fr 1fr'],
                    gap: 4
                  }}>
                    <div>
                      <p sx={{ mb: 1, fontWeight: 600 }}>Employee Signature:</p>
                      <div sx={{ 
                        borderBottom: '1px solid #ced4da',
                        height: '1.5rem'
                      }}></div>
                    </div>
                    <div>
                      <p sx={{ mb: 1, fontWeight: 600 }}>Date:</p>
                      <div sx={{ 
                        borderBottom: '1px solid #ced4da',
                        height: '1.5rem'
                      }}></div>
                    </div>
                  </div>
                </div>
                
                <p sx={{ fontStyle: 'italic', fontSize: '0.9rem', mt: 3 }}>
                  Please sign and return this form to Human Resources. A copy will be placed in your personnel file.
                </p>
              </div>
            </div>
            
            {/* Download Section */}
            <div id="download" sx={{
              bg: 'primaryMuted',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mt: 5
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                Download Full Employee Handbook Template
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                mb: 3,
                lineHeight: 1.6
              }}>
                Get the complete, customizable template with all sections to create a comprehensive 
                employee handbook for your organization. The full template includes:
              </p>
              
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0,
                mb: 3,
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr 1fr'],
                gap: 2
              }}>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> MS Word & PDF formats
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Editable template text
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Legal compliance guidance
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Customization instructions
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> All policy sections
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Acknowledgment forms
                </li>
              </ul>
              
              <div sx={{
                display: 'flex',
                gap: 3,
                flexWrap: 'wrap'
              }}>
                <Link to="/contact/" sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    bg: 'primaryDark',
                    color: 'white'
                  }
                }}>
                  Download Full Template <RiFileDownloadLine />
                </Link>
                
                <Link to="/contact/" sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  boxShadow: '0 2px 6px rgba(0,0,0,0.05)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                  }
                }}>
                  Request Customization <RiMailLine />
                </Link>
              </div>
            </div>
            
            {/* Navigation Links */}
            <div sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 4,
              pt: 3,
              borderTop: '1px solid',
              borderColor: 'rgba(0,0,0,0.1)'
            }}>
              <Link to="/policies" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                color: 'primary',
                textDecoration: 'none',
                fontSize: '0.95rem',
                fontWeight: 600
              }}>
                <RiArrowLeftSLine /> Back to Policies
              </Link>
              
              <Link to="/policies/performance-management" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                color: 'primary',
                textDecoration: 'none',
                fontSize: '0.95rem',
                fontWeight: 600
              }}>
                Next: Performance Management <RiArrowRightSLine />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default EmployeeHandbookPage 